<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-left">
            <h4>System settings</h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="fnUpdate">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  autocomplete="off_police_check_receiving_email"
                  placeholder=" "
                  v-model="userData.police_check_receiving_email"
                />
                <label class="floatingLabel" for="firstName"
                  >Police check receiving email</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.police_check_receiving_email"
                >
                  {{ errors.police_check_receiving_email[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  autocomplete="off_contact_receiving_email"
                  placeholder=" "
                  v-model="userData.contact_receiving_email"
                />
                <label class="floatingLabel" for="contact_receiving_email"
                  >Contact receiving email</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.contact_receiving_email"
                >
                  {{ errors.contact_receiving_email[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-info btn-sm">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import * as messageService from "../../services/message.service";
import * as systemSettingsService from "../../services/systemSettings.service";

export default {
  name: "SystemSettings",
  components: {},
  data() {
    return {
      errors: {},
      loginUserObj: {},

      userData: {
        id: "",
        police_check_receiving_email: "",
        contact_receiving_email: "",
      },
    };
  },
  methods: {
    fnGetSS: async function () {
      this.errors = {};

      try {
        let res = await systemSettingsService.single(1);
        Object.assign(this.userData, res.data);
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "System settings - update",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "System settings - update",
              "Something wrong"
            );
            break;
        }
      }
    },

    fnUpdate: async function () {
      this.errors = {};

      try {
        await systemSettingsService.update(this.userData.id, this.userData);
        messageService.fnSweetAlertSuccessToast(
          "System settings - Successfully updated"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "System settings - update",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "System settings - update",
              "Something wrong"
            );
            break;
        }
      }
    },
  },
  created() {
    this.fnGetSS();
  },
};
</script>
